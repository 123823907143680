import {
  ref, onMounted, onBeforeMount, getCurrentInstance, onBeforeUnmount
} from 'vue'
import { priceUseCase, getDataOneKategoriBarang } from '@/domain/usecase'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import moment from 'moment'
import { Icon } from '@iconify/vue'
import circleFill from '@iconify-icons/akar-icons/circle-fill'
import PriceForm from '@/views/master/transactions/price/PriceForm.vue'
import PriceFormItems from '@/views/master/transactions/price/price-detail/PriceFormItems.vue'
import 'jspreadsheet-ce/dist/jspreadsheet.css'
import jexcel from 'jspreadsheet-ce'
import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

export default {
  components: {
    Icon,
    PriceForm,
    PriceFormItems
  },
  setup() {
    const app = getCurrentInstance()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const route = useRoute() as any
    const router = useRouter()
    const store = useStore()
    const data = ref({
      Id: -1
    }) as any
    const detailItem = ref(null) as any
    const icons = ref({
      circle: circleFill
    })
    const refEditPrice = ref()
    const refEditHeaderPrice = ref()
    const onCopy = ref(false)
    const sliderActive = ref(false)
    const sliderActiveItems = ref(false)
    const indexKategori = ref(0)
    const dataDetailPerubahan = ref({}) as any
    const dataPerubahan = ref([])
    const dataBaseId = ref(null) as any
    const currentHarga = ref(null)
    const refExcel = ref()
    const currentPriceId = ref(route.params.id)
    const currentCategoryId = ref(null) as any
    const dataExcel = ref([
      {}
    ])
    const titleHeaderTable = ref({
      Name: '',
      Code: ''
    })
    const fromIdPrice = ref(null)
    const jExcelOptions = ref({
      data: dataExcel.value,
      hideIndex: true,
      allowInsertColumn: false,
      allowDeleteColumn: false,
      allowDeleteRow: route.meta.permission.update,
      allowInsertRow: route.meta.permission.update,
      contextMenu: false,
      editable: route.meta.permission.update,
      columns: [
        {
          name: 'MinValue',
          title: 'Min',
          // mask: '#.##'
          // type: 'number',
        },
        {
          name: 'MaxValue',
          title: 'Max',
          // mask: '#.##'
          // type: 'number',
        },
        {
          name: 'MaxVolume',
          title: 'm3',
          readOnly: true,
          // mask: '#.##'
          // type: 'number',
        },
        {
          name: 'DoorToDoorPrice',
          title: 'D-D',
          // mask: '#.##'
          // type: 'number',
        },
        {
          name: 'AgentToDoorPrice',
          title: 'A-D',
          // mask: '#.##'
          // type: 'number',
        },
        {
          name: 'DoorToAgentPrice',
          title: 'D-A',
          // mask: '#.##'
          // type: 'number',
        },
        {
          name: 'AgentToAgentPrice',
          title: 'A-A',
          // mask: '#.##'
          // type: 'number',
        },
        {
          name: 'MaxLength',
          title: 'Panjang',
          // type: 'number',
        },
        {
          name: 'MaxWidth',
          title: 'Lebar',
          // type: 'number',
        },
        {
          name: 'MaxHeight',
          title: 'Tinggi',
          // type: 'number',
        }
      ],
      nestedHeaders: [
        [
          {
            title: 'Berat',
            colspan: '2',
          },
          {
            title: 'Volume',
            colspan: '1',
          },
          {
            title: 'Jenis Pengiriman',
            colspan: '4',
          },
          {
            title: 'Dimensi',
            colspan: '3',
          },
        ]
      ],
      columnSorting: true,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onchange: (instance: any, cell: any, x: any, y: any, value: any) => { },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onselection: (instance: any, cell: any, x: any, y: any, value: any) => { }
    })
    const jExcelObj = ref(null) as any
    const currentId = ref(null) as any
    const loadingExcel = ref(false)
    const showSubmitExcel = ref(false)
    const currentCategoryDrop = ref(null) as any

    const redirectEdit = (val: any) => {
      onCopy.value = false
      currentHarga.value = val
      sliderActive.value = true
      fromIdPrice.value = null
    }

    const editHeader = () => {
      onCopy.value = false
      currentHarga.value = data.value
      sliderActive.value = true
      fromIdPrice.value = null
    }

    const addPrice = (val: any) => {
      onCopy.value = false
      currentHarga.value = val
      sliderActive.value = true
      fromIdPrice.value = val.Id
    }

    const duplicatePrice = () => {
      onCopy.value = true
      currentHarga.value = data.value
      sliderActive.value = true
      fromIdPrice.value = null
    }

    const addPerubahanHarga = (val: any) => {
      onCopy.value = false
      // currentHarga.value = val
      currentHarga.value = null
      fromIdPrice.value = route.params.id
      sliderActive.value = true
    }

    const closeSidebar = () => {
      sliderActive.value = false
    }

    const closeSidebarItems = () => {
      sliderActiveItems.value = false
    }

    const showDropDown = (evt: any, item: any) => {
      currentHarga.value = item
      refEditPrice.value.toggle(evt)
    }

    const showDropDownCategory = (evt: any, item: any) => {
      // currentCategoryDrop.value = item
      refEditHeaderPrice.value.toggle(evt)
    }

    const showFormAddItems = () => {
      currentCategoryDrop.value = null
      sliderActiveItems.value = true
    }

    const showFormEditItems = (val: any) => {
      sliderActiveItems.value = true
    }

    const onTableChange = (instance: any, cell: any, x: any, y: any, value: any) => {
      // eslint-disable-next-line eqeqeq
      if (x == 7 || x == 8 || x == 9) {
        const lengthTemp = jExcelObj.value.getValueFromCoords(x === 7 ? value : 7, y) ?? 0
        const widthTemp = jExcelObj.value.getValueFromCoords(x === 8 ? value : 8, y) ?? 0
        const heightTemp = jExcelObj.value.getValueFromCoords(x === 9 ? value : 9, y) ?? 0
        const volume = lengthTemp * widthTemp * heightTemp
        jExcelObj.value.setValueFromCoords(2, y, Number(volume), true)
        jExcelObj.value.ignoreEvents = false
      }
      if (!showSubmitExcel.value) {
        showSubmitExcel.value = true
      }
      jExcelObj.value.ignoreEvents = true
      if (!Number.isNaN(Number(value))) {
        jExcelObj.value.setValue(cell, Number(value))
      } else {
        jExcelObj.value.setValue(cell, '')
      }
      jExcelObj.value.ignoreEvents = false
    }

    const getDataHargaDetail = async (catId: any) => {
      currentCategoryId.value = catId
      const response = await priceUseCase.getPriceDetails(catId)
      if (!response.error) {
        titleHeaderTable.value = {
          Name: response.result.GoodCategoryName,
          Code: response.result.GoodCategoryUnitCode
        }
        dataExcel.value = response.result.PriceDetails
        jExcelObj.value.setData((dataExcel.value.length >= 0) ? dataExcel.value : [{}])
      } else {
        $toast.add({
          severity: 'error', detail: 'Gagal mengambil harga, silahkan coba kembali', group: 'bc', life: 3000
        })
      }
      store.dispatch('hideLoading')
    }

    const initListDetailItemId = async (val: any) => {
      currentCategoryDrop.value = val
      if (val.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        detailItem.value = val[0]
        // await getOneKategoriBarang(val[0].Id)
        await getDataHargaDetail(val[0].Id)
      } else {
        detailItem.value = null
      }
    }

    const initExcel = () => {
      const docs = document.getElementById('spreadsheet')
      jExcelOptions.value.onchange = onTableChange
      jExcelObj.value = jexcel(docs, jExcelOptions.value)
      jExcelObj.value.hideIndex()
      // jExcelObj.value.onchange = onTableChange
    }

    const changeItemsId = async (index: any, val: any) => {
      store.dispatch('showLoading')
      jExcelObj.value.destroy()
      initExcel()
      showSubmitExcel.value = false
      indexKategori.value = index
      // currentCategoryDrop.value = val
      detailItem.value = val
      // await getOneKategoriBarang(val.KategoriId)
      await getDataHargaDetail(val.Id)
      store.dispatch('hideLoading')
    }

    const checkBaseId = () => {
      if (Number(route.params.id) !== Number(data.value.BaseId) && data.value.BaseId) {
        const fullPath = route.fullPath.replace(route.params.id, '')
        window.location.href = `${fullPath}${data.value.BaseId}`
      }
    }

    const getAllData = async () => {
      store.dispatch('showLoading')
      const response = await priceUseCase.getSinglePrice(currentPriceId.value)
      if (response.error) {
        console.log('error')
      } else {
        data.value = response.result
        // checkBaseId()
        await initListDetailItemId(response.result.GoodCategories)
      }
      closeSidebar()
      store.dispatch('hideLoading')
    }

    const changeBasisId = async (val: any) => {
      dataBaseId.value = await val
      currentPriceId.value = dataBaseId.value.Id
      getAllData()
    }

    const getDataPerubahanHarga = async () => {
      const response = await priceUseCase.getSinglePriceChilds(route.params.id)
      dataPerubahan.value = await response.result
      if (dataBaseId.value === null && dataPerubahan.value.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        dataBaseId.value = dataPerubahan.value[0]
        // eslint-disable-next-line prefer-destructuring
        // currentCategoryDrop.value = dataPerubahan.value[0]
        changeBasisId(dataBaseId.value)
      }
    }

    const classItemPerubahan = (val: any) => {
      if (val.Id === dataBaseId.value.Id) {
        return 'background-orange-lighten-5'
      }
      return 'hover:background-orange-lighten-5'
    }

    const classItemMenu = (val: any) => {
      if (val.Id === detailItem.value.Id) {
        return 'chip-orange'
      }
      return 'grey-bg-no-border'
    }

    const deleteData = (val: any) => {
      $confirm.require({
        header: 'Hapus Perubahan Harga',
        message: 'Apakah anda yakin ingin menghapusnya?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await priceUseCase.deleteData(val.Id)
          if (response.error) {
            $toast.add({
              severity: 'error', detail: response.message, group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: response.result.detail ?? response.result.Detail, group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
            getDataPerubahanHarga()
          }
        }
      })
    }

    const deleteHeader = () => {
      $confirm.require({
        header: 'Harga',
        message: 'Apakah anda yakin ingin menghapusnya?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await priceUseCase.deleteData(data.value.Id)
          if (response.error) {
            $toast.add({
              severity: 'error', detail: 'Gagal menghapus harga, silahkan coba kembali', group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
            router.push({ name: 'master-harga' })
          }
        }
      })
    }

    const proccessDeleteCategory = async () => {
      const dataForm = {
        HargaId: dataBaseId.value.Id,
        KategoriId: currentCategoryId
      }

      const response = await priceUseCase.deleteTypeItems(dataForm)
      return response
    }

    const deleteCategory = () => {
      $confirm.require({
        header: 'Tipe Barang',
        message: 'Apakah anda yakin ingin menghapusnya?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await proccessDeleteCategory()
          if (response.error) {
            $toast.add({
              severity: 'error', detail: 'Gagal menghapus tipe barang, silahkan coba kembali', group: 'bc', life: 3000
            })
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
            })
            // getAllData()
            // initListDetailItemId(dataDetailPerubahan.value.MsHargaKategoris)
            // dataDetailPerubahan.value.MsHargaKategoris.splice(indexKategori.value, 1)
          }
          store.dispatch('hideLoading')
        }
      })
    }

    const proccessSaveDataExcel = async (id: number, dataForm: any) => {
      store.dispatch('showLoading')
      const response = await priceUseCase.submitDataExcelMultiple(id, dataForm)

      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
      }
      store.dispatch('hideLoading')
    }

    const isEmpty = (val: any) => {
      val = val === '' ? 0 : val
      return val
    }

    const checkIsPriceEmpty = (array: any) => {
      let count = 0
      for (let i = 0; i < array.length - 3; i++) {
        if (array[i] === '') {
          count++
        }
      }
      return count
    }

    const submitDataExcel = () => {
      const dataForm = []
      const value = jExcelObj.value.getData()
      let hasError = false
      for (let i = 0; i < value.length; i++) {
        // console.log(`lklk${i}`, value[i].filter((val: any) => val === ''))
        // const sizeArr = value[i].filter((val: any) => val === '').length
        const sizeArr = checkIsPriceEmpty(value[i])
        const zeroSizeArr = value[i].filter((val: any) => Number(val) < 0).length
        if (sizeArr === 0 && zeroSizeArr === 0) {
          const obj = {
            minValue: value[i][0],
            maxValue: value[i][1],
            maxLength: value[i][7],
            maxWidth: value[i][8],
            maxHeight: value[i][9],
            agentToAgentPrice: value[i][6],
            agentToDoorPrice: value[i][4],
            doorToAgentPrice: value[i][5],
            doorToDoorPrice: value[i][3]
          }
          dataForm.push(obj)
        } else if (zeroSizeArr > 0) {
          hasError = true
          $toast.add({
            severity: 'error', detail: `Pada baris ke ${i + 1} tidak boleh memiliki nilai < 0`, group: 'bc', life: 3000
          })
          break
        } else if (sizeArr > 0) {
          hasError = true
          $toast.add({
            severity: 'error', detail: `Pada baris ke ${i + 1} tidak boleh kosong`, group: 'bc', life: 3000
          })
          break
        }
      }

      if (dataForm.length > 0 && !hasError) {
        proccessSaveDataExcel(currentCategoryId.value, dataForm)
      }
    }
    const getAll = () => {
      getAllData()
      getDataPerubahanHarga()
    }

    onBeforeMount(() => {
      currentId.value = route.params.id
    })

    onMounted(() => {
      getAllData()
      initExcel()
      getDataPerubahanHarga()
    })

    onBeforeUnmount(() => {
      jExcelObj.value.destroy()
    })

    return {
      refEditPrice,
      refEditHeaderPrice,
      refExcel,
      data,
      moment,
      icons,
      detailItem,
      sliderActive,
      jExcelObj,
      sliderActiveItems,
      loadingExcel,
      showSubmitExcel,
      currentCategoryDrop,
      route,
      showDropDownCategory,
      getAllData,
      redirectEdit,
      classItemMenu,
      changeItemsId,
      closeSidebar,
      closeSidebarItems,
      showFormAddItems,
      deleteData,
      deleteCategory,
      showDropDown,
      submitDataExcel,
      showFormEditItems,
      dataPerubahan,
      dataBaseId,
      classItemPerubahan,
      currentHarga,
      addPrice,
      onCopy,
      addPerubahanHarga,
      changeBasisId,
      dataDetailPerubahan,
      getDataPerubahanHarga,
      deleteHeader,
      editHeader,
      fromIdPrice,
      getAll,
      duplicatePrice,
      titleHeaderTable,
      checkPermission,
      PermissionEnum,
      currentCategoryId
    }
  }
}
